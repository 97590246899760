import store from "../../store/main/store"
import {CHAT} from '../../store/modulesNames'
import {ACT_CHAT_GET_SCREEN_MESSAGES} from '../../store/actionsTypes'

const mixin = {
    methods: {
        async scrollingToElement(id, commentId) {
            if (!id) return
            let element = document.querySelector('#msg' + id)

            if (!element) {
                store.dispatch(`${CHAT}/setUpdating`, true)
                await store.dispatch(`${CHAT}/${ACT_CHAT_GET_SCREEN_MESSAGES}`, {id, commentId})
                await new Promise((resolve) => this.$nextTick(resolve))
                element = document.querySelector('#msg' + id)
                store.dispatch(`${CHAT}/setUpdating`, false)
            }

            element.scrollIntoView({block: "center"})

            setTimeout(function() {
                element.classList.add('scroll-msg');
                element.style.opacity = 0.3;
            }, 500);
            setTimeout(function() {
                element.style.opacity = 1;
            }, 800);
            setTimeout(function() {
                element.style.opacity = 0.3;
            }, 1100);
            setTimeout(function() {
                element.style.opacity = 1;
            }, 1400);
            setTimeout(function() {
                element.classList.remove('scroll-msg');
            }, 1700);
        },
    }
}

export default mixin