
import ChatBase from "./ChatBase.vue"

import { mapGetters } from "../../../vuex"
import { CHAT } from "../../store/modulesNames"
import { GET_CHAT_MESSAGES } from "../../store/gettersTypes"

import { msgDataTypes, msgDirection } from '../../declarations'


export default {
    name: "Chat",
    extends: ChatBase,
    props: {
    },
    computed: {
        messages() {
            let msgs = this[GET_CHAT_MESSAGES]
            let result = [], groupMsgs = []
            let isGroupMsg = false
            let prev_msg, cur_msg, date_item, currentGroupId, startGroupMsg_Item
            let message_item
            for (let i = 0, count = msgs.length; i < count; i++) {
                cur_msg = msgs[i]
                date_item = this.getDateItem(prev_msg, cur_msg)
                message_item = this.getMessageItem(cur_msg)
                if (date_item) {
                    if (result.length) {
                        // Отображение иконки у последнего за день сообщения
                        let prev_result = result[result.length - 1]
                        if (message_item.type !== 'system' && prev_result.type !== 'system') {
                            prev_result.seriesMessagesEnd = true
                        }
                    }
                    result.push(date_item)
                }
                let prevResultIsSystem = result[result.length - 1] && result[result.length - 1].type === 'system'
                let prevMsgSenderSame = msgs[i - 1] && msgs[i - 1].senderId === cur_msg.senderId
                let nextMsg = msgs[i + 1]
                let nextMsgSenderSame = nextMsg && nextMsg.senderId === cur_msg.senderId
                // Отображение фио у первого cooбщения в серии сообщений одного пользователя
                if (message_item.type !== 'system' && (i === 0 || !prevMsgSenderSame || prevResultIsSystem)) {
                    message_item.seriesMessagesStart = true
                }
                // Отображение иконки у последнего cooбщения в серии сообщений одного пользователя
                if ((message_item.type !== 'system' && (i === msgs.length - 1 || !nextMsgSenderSame ||  nextMsg.dataType === 'system'))) {
                    message_item.seriesMessagesEnd = true
                }

                if (cur_msg && !cur_msg.data) cur_msg.data = {}
                if (prev_msg && !prev_msg.data) prev_msg.data = {}
                if (nextMsg && !nextMsg.data) nextMsg.data = {}

                isGroupMsg = cur_msg.data.hasOwnProperty('fileGroupId')
                let curMsgFileGroupId = isGroupMsg && cur_msg.data.fileGroupId && cur_msg.data.fileGroupId.length
                let prevMsgFileGroupId = prev_msg && prev_msg.data.fileGroupId && prev_msg.data.fileGroupId.length || false
                let nextMsgFileGroupId = nextMsg && nextMsg.data.fileGroupId && nextMsg.data.fileGroupId.length || false
                if ((!i && isGroupMsg) || (i && isGroupMsg && 
                    (!prev_msg.data.hasOwnProperty('fileGroupId') || curMsgFileGroupId !== prevMsgFileGroupId))) {
                        message_item.startFileGroup = true
                        currentGroupId = curMsgFileGroupId
                        groupMsgs = []
                        startGroupMsg_Item = message_item
                }
                let existedGroupIdMsg = result.find(m => isGroupMsg && cur_msg.data && m.fileGroupId === cur_msg.data.fileGroupId)
                if (isGroupMsg) {
                        groupMsgs.push({msgId: cur_msg.id, type: message_item.sub_type, ...message_item.msg})
                        if (cur_msg.data && cur_msg.data.entities) startGroupMsg_Item.entities = cur_msg.data.entities
                    }
                if (!existedGroupIdMsg) {
                    if ((isGroupMsg && nextMsg && !nextMsg.data.hasOwnProperty('fileGroupId') || 
                    (curMsgFileGroupId && curMsgFileGroupId !== nextMsgFileGroupId) || 
                    (isGroupMsg && !nextMsg))) {
                        message_item.endFileGroup = true
                        isGroupMsg = false
                        startGroupMsg_Item.groupMsgs = groupMsgs
                    }
                }
                if (prev_msg && prev_msg.senderId && !prev_msg.hasOwnProperty('fileGroupId')) {
                    if (existedGroupIdMsg) {
                        existedGroupIdMsg.groupMsgs.push({msgId: cur_msg.id, type: message_item.sub_type, ...message_item.msg})
                        continue
                    }
                }
                if (!isGroupMsg && !message_item.endFileGroup) {
                    result.push(message_item)
                }
                else if (message_item && message_item.endFileGroup && !existedGroupIdMsg) result.push(startGroupMsg_Item)
                if (cur_msg.senderId === this.uid && cur_msg.type === msgDirection.OUT &&
                    (cur_msg.dataType === msgDataTypes.MSG_DATA_TYPE_DATA
                    )) {
                    if (cur_msg.senderId === this.uid) this.my_last_message_id = cur_msg.id
                }
                prev_msg = cur_msg
            }
            return result
        },
        ...mapGetters(CHAT, [GET_CHAT_MESSAGES]), 
    },
}

