//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {
    GET_MESSAGE_BY_ID,
    GET_CHAT,
    GET_SELECTED_CHAT,
    GET_MY_CONTACT,
    GET_MERGED_CONTACT_BY_ID,
    GET_CONTACT_STATUS_TEXT,
    GET_CHAT_TYPINGS,
    GET_SERVER_API,
    GET_IS_ROLES_SUPPORTED,
    GET_USER_PARAMS,
    GET_PHONE_LIST_BY_ID,
    GET_OPENED_MAIN_TYPE,
} from "../../../store/gettersTypes"
import {
    ACT_INFO_PUSH,
    ACT_INFO_CLEAR,
    ACT_MODAL_OPEN,
    ACT_SET_MSG_COMMENT_SUBSCRIBED,
    ACT_MAIN_TYPE_BACK,
} from "../../../store/actionsTypes"
import {
    CHAT,
    CHATS,
    MODAL,
    CONTACTS,
    LOGIN,
    USERDATA,
    CONTENT_MANAGER,
    INFO,
} from "../../../store/modulesNames"

import { i18n } from '../../../../ext/i18n'
import IconImage from '../../IconImage.vue'
import TypingStatus from "../../listItems/TypingStatus.vue"
import ChatMessageMixin from "../../chat/chat-message-mixin"

export default {
    name: "MainHeaderContact",
    components: {
        'icon-image': IconImage,
        TypingStatus,
    },
    mixins: [ChatMessageMixin],
    async beforeMount() {
        const isMsg = this.auxParams && this.auxParams.message
        if (isMsg) this.msg = this.auxParams && this.auxParams.message
        else {
            const commentId = this.auxParams && this.auxParams.commentId
            let message = this[GET_MESSAGE_BY_ID](commentId)
            let { cid, cidType } = this[GET_SELECTED_CHAT]
            if (!message) {
                message = await app.proto.getMessage({ cid, cidType, id: commentId })
            }
            if (message) {
                this.message = message
                this.msg = this.getMsgNameByType(this.getMessageItem(message))
            }
        }
        this.isCommentsSubscribed = this.auxParams && this.auxParams.isCommentsSubscribed || this.message && this.message.commentsSubscribed
    },
    data() {
        return {
            showTitle: false,
            canBack: true,
            isCommentsSubscribed: false,
            msg: '',
            message: null,
        }
    },
    watch: {
        auxParams(val) {
            if (val.hasOwnProperty('isCommentsSubscribed')) this.isCommentsSubscribed = val.isCommentsSubscribed
        },
    },
    computed: {
        cid() {
            return this[GET_SELECTED_CHAT].cid 
        },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP,
        getTitle() {
            return this.isCommentsSubscribed ? this.$t("chat.unsubscribe-comments") : this.$t("chat.subscribe-comments")
        },
        auxParams() {
            return this[GET_OPENED_MAIN_TYPE] && this[GET_OPENED_MAIN_TYPE].auxParams
        },
        commentId() {
            const id = this.auxParams && this.auxParams.commentId
            return id
        },
        commentsSubscribed() {
            const isCommentsSubscribed = this.auxParams && this.auxParams.isCommentsSubscribed || this.message && this.message.commentsSubscribed
            return isCommentsSubscribed
        },                
        /*msg() {
            const isMsg = this.auxParams && this.auxParams.message
            if (isMsg) return this.auxParams && this.auxParams.message
            else {
                const commentId = this.auxParams && this.auxParams.commentId
                let message = this[GET_MESSAGE_BY_ID](commentId)
                let _msg = this.getMsgNameByType(this.getMessageItem(message))
                return _msg
            }
        },*/
        typing: function () {
            let { cid, cidType } = this
            return Boolean(this[GET_CHAT_TYPINGS]({cid, cidType}).length)
        },
        ...mapGetters(CHAT, [GET_MESSAGE_BY_ID]),
        ...mapGetters(CHATS, [GET_CHAT, GET_SELECTED_CHAT, GET_CHAT_TYPINGS]),
        ...mapGetters(CONTENT_MANAGER, [GET_OPENED_MAIN_TYPE]),
        ...mapGetters(CONTACTS, [GET_IS_ROLES_SUPPORTED, GET_MY_CONTACT, GET_MERGED_CONTACT_BY_ID, GET_CONTACT_STATUS_TEXT, GET_PHONE_LIST_BY_ID]),
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
    },
    methods: {
        headerMouseOver(e) {
            if (e.target.className === 'fiospan') {
                const childEl = e.target
                const childW = childEl.offsetWidth
                const parentW = e.relatedTarget && e.relatedTarget.offsetWidth
                childW > parentW ? this.showTitle = true : this.showTitle = false
            }
        },
        subscribeToComments(isSubscribed) {
            const commentId = this.commentId
            this[ACT_SET_MSG_COMMENT_SUBSCRIBED]({id: commentId, status: isSubscribed})
            this.isCommentsSubscribed = isSubscribed
        },
        doOpenInfo: function () {
            this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid: this.cid}})
        },
        goBack() {
            this[ACT_MAIN_TYPE_BACK]()
        },
        openSearchChat() {
            this[ACT_INFO_CLEAR]()
            this[ACT_INFO_PUSH]({type: 'search-in-chat', params: {cid: this.cid, cidType: this.cidType, commentId: this.commentId}})
        },            
        ...mapActions(CONTENT_MANAGER, [ACT_MAIN_TYPE_BACK]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(CHAT, [ACT_SET_MSG_COMMENT_SUBSCRIBED]),
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_CLEAR]),
    }
}
