/** Login **/
export const ACT_SET_LOGGING_IN = 'setLoggingIn';
export const ACT_LOGIN = 'login';
export const ACT_RELOGIN = 'relogin';
export const ACT_ON_LOGGED_IN = 'onLoggedIn';
export const ACT_LOGOUT = 'logout';
export const ACT_SAVE_LOGIN_DATA = 'saveLoginData';
export const ACT_DROP_LOGIN_DATA = 'actDropLoginData';
export const ACT_OPEN_LOGOUT_DIALOG = 'actOpenLogoutDialog';
export const ACT_DAYS_IN_OPERATION = 'actDaysInOperation';
export const ACT_CLEAR_RELOGIN_INTERVAL = 'clearReloginInterval';
export const ACT_SET_SERVER_API = 'setServerApi';
export const ACT_START_OAUTH = 'actStartOauth';
export const ACT_HANDLE_TOKENS_EVENT = 'actHandleTokensEvent';
export const ACT_UPDATE_OAUTH2_TOKENS = 'actUpdateCookieTokens';

/** Socket **/
export const ACT_SOCKET_RECONNECT = 'socketReconnect'
export const ACT_SET_CONNECTION_STATUS = 'actSetConnectionStatus'
export const ACT_SOCKET_INIT = 'socketInit'
export const ACT_SOCKET_CONNECT = 'socketConnect'

/** ContentManager **/
export const ACT_PUSH_MAIN_TYPE = 'actPushMainType';
export const ACT_REPLACE_MAIN_TYPE = 'actReplaceMineType';
export const ACT_MAIN_TYPE_BACK = 'actMineTypeBack';
export const ACT_MAIN_TYPE_CLEAR = 'actMainTypeClear';
export const ACT_MAIN_TYPE_CHANGE_AUX_PARAMS = 'actMainTypeChangeAuxParams'

/** Contacts **/
export const ACT_UPDATE_CONTACTS = 'actUpdateContacts'
export const ACT_PUSH_FAVOURITES = 'actPushFavourite'
export const ACT_SPLICE_FAVOURITES = 'actSpliceFavourite'
export const ACT_ADD_FAVOURITE = 'actAddFavourite'
export const ACT_CHANGE_FAVOURITES_MODE = 'actChangeFavouritesMode'
export const ACT_SET_FAVOURITES = 'actSetFavouritesList'
export const ACT_SELECT_CONTACT = 'actSelectContact'
export const ACT_UPDATE_FAVOURITES = 'actUpdateFavourites'
export const ACT_UPDATE_CONTACT_STATUS = 'actUpdateContactStatus'
export const ACT_CHANGE_CONTACT = 'actChangeContact'
export const ACT_ADD_CONTACT_CONNECTIONS = 'actAddContactConnections'
export const ACT_ADD_CONTACT = 'actAddContact'
export const ACT_ADD_CONTACTS_TO_LIST = 'actAddContactsToList'
export const ACT_ADD_CONTACT_TO_LIST = 'actAddContactToList'
export const ACT_DELETE_CONTACT = 'actDeleteContact'
export const ACT_LOAD_BOT_CONTACT = 'actLoadContact'
export const ACT_UPDATE_CONTACTS_BIRTHDAYS = 'actUpdateContactsBirthdays'
export const ACT_SEARCH_CONTACTS = 'actSearchContacts'
export const ACT_ADD_CONTACT_SHORT_INFO = 'actAddContactShortInfo'
export const ACT_UPDATE_SEARCHED_CONTACT = 'actUpdateSearchedContact'
export const ACT_ADD_BOT_CONTACT = 'actAddBotContact'
export const ACT_UPDATE_SEARCH_FILTER = 'actUpdateSearchFilter'
export const ACT_SET_CONTACTS_FILTER = 'actSetContactsFilter'
export const ACT_UPDATE_ROLE_REVISION = 'actUpdateRoleRevision'
export const ACT_REFRESH_UPDATE_CONTACTS = 'actRefreshUpdateContacts'
export const ACT_UPDATE_MY_CONTACT_ROLE_ACTIONS_INFO = 'actUpdateMyContactRoleActionsInfo'
export const ACT_UPDATE_SINGLE_CONTACT = 'actUpdateSingleContact'
export const ACT_SET_BIRTHDAYS_WATCHED = 'actSetBirthdaysWatched'
export const ACT_FORM_PROJECT_FORMAT_CONTACT = 'actPrepareContactToProjectFormat'
export const ACT_FORM_CONTACT_DISPLAY_NAME = 'actFormContactDisplayName'
export const ACT_HANDLE_FAVOURITES_EVENT = 'actHandleFavouritesEvent'
export const ACT_HANDLE_ROLES_REVISION_EVENT = 'actHandleRolesRevisionEvent'
export const ACT_HANDLE_CONTACT_CONNECTION_CHANGE_EVENT = 'actHandleContactConnectionChangeEvent'
export const ACT_HANDLE_CONTACT_CHANGE_EVENT = 'actHandleContactChangeEvent'
export const ACT_INVITE_GUEST_CONTACT = 'actInviteGuestContact'


/** Channels **/
export const ACT_SELECTED_CHANNEL = 'selected'
export const ACT_CREATE_CHANNEL = 'createChannel'
export const ACT_CHANGE_CHANNEL = 'changeChannel'
export const ACT_CHANGE_CHANNEL_SETTINGS = 'changeChannelSettingsParams'
export const ACT_SET_CHANNEL_MUTED = 'actSetChannelMuted'
export const ACT_SET_CHANNEL_MARKED = 'actSetChannelMarked'
export const ACT_SET_CHANNEL_PINED = 'actSetChannelPinned'
export const ACT_DEL_CHANNEL = 'delChannel'
export const ACT_REMOVE_FROM_CHANNELS_LIST = 'removeFromChannelsList'
export const ACT_CHANNELS_OPEN = 'open'
export const ACT_GET_CHANNEL_DETAILS = 'actGetChennelDetails'
export const ACT_DELETE_CHANNEL_USERS = 'deleteChannelUsers'
export const ACT_CHANGE_CHANNEL_USERS = 'changeChannelUsers'
export const ACT_LEAVE_FROM_CHANNEL = 'leaveFromChannel'
export const ACT_SUBSCRIBE_ON_CHANNEL = 'subscribeOnChannel'
export const ACT_HANDLE_CHANNEL_CHANGE_EVENT = 'handleChannelChangeEvent'
export const ACT_UPDATE_CHANNEL = 'actUpdateChannel'

/** Channel **/
export const ACT_OPEN_CHANNEL = 'openChannel'
export const ACT_SET_CHANNEL_VISIBLE = 'visible'
export const ACT_UPDATE_PUBLICATION = 'updatePublication'
export const ACT_PUBLICATION_LIKE = 'actPublicationLike'
export const ACT_ADD_PUBLICATION_COMMENT = 'actAddPublicationComment'
export const ACT_CHECK_PUBLICATION_IN_SERVER = 'checkPublicationInServer'
export const ACT_CHANNEL_SET_SEARCH = 'actChannelSetSearch'
export const ACT_CHANNEL_SEARCH = 'actChannelSearch'

/** Chats **/
export const ACT_SELECTED_CHAT = 'selected'
export const ACT_ADD_CHAT = 'addChat'
export const ACT_CHANGE_CHAT = 'changeChat'
export const ACT_UPDATE_CHAT = 'actUpdateChat'
export const ACT_CHANGE_CHAT_SETTINGS = 'actChangeChatSettings'
export const ACT_SET_CHAT_MARKED = 'actSetChatMarked';
export const ACT_SET_CHAT_MUTED = 'actSetChatMuted';
export const ACT_SET_CHAT_PINED = 'actSetChatPinned'
export const ACT_SET_CHAT_DRAFT = 'setChatDraft';
export const ACT_CHATS_ADD_MEMBERS_DIALOG = 'actChatsAddMemberDialog';
export const ACT_CHATS_ADD_MEMBERS = 'actChatsAddMembers';
export const ACT_CHATS_REMOVE_MEMBER_DIALOG = 'actChatsRemoveMemberDialog';
export const ACT_CHATS_REMOVE_MEMBERS = 'actChatsRemoveMembers';
export const ACT_REMOVE_CHAT_API = 'removeChatApi'
export const ACT_REMOVE_CHAT = 'removeChat'
export const ACT_ADD_TYPING_CID = 'addTypingCid'
export const ACT_DEL_TYPING_CID = 'delTypingCid'
export const ACT_SET_MSG_RECEIVED = 'setMsgReceived'
export const ACT_WATCH_MESSAGES = 'watchUnwatchedMessages'
export const ACT_WATCH_ALL_CHAT_MESSAGES = 'watchAllChatUnwatchedMessages'
export const ACT_WATCH_ALL_MESSAGES = 'watchAllUnwatchedMessages'
export const ACT_WATCH_MESSAGE = 'watchMessage'
export const ACT_UPDATE_UNWATCHED_MESSAGES = 'updateUnwatchedMessages'
export const ACT_UPDATE_CHAT_LAST_MESSAGE = 'updateChatLastMessage';
export const ACT_SET_CHAT_POSITION = 'setChatPosition';
export const ACT_SET_CHAT_COMMENT_POSITION = 'setChatCommentPosition';
export const ACT_HANDLE_MESSAGE_EVENT = 'handleMessageEvent';
export const ACT_HANDLE_MESSAGE_CHANGE_EVENT = 'actHandleMessageChangeEvent';
export const ACT_HANDLE_MESSAGE_UPDATE_EVENT = 'actHandleMessageUpdateEvent';
export const ACT_HANDLE_MESSAGE_REACTION_EVENT = 'actHandleMessageReactionEvent';
export const ACT_SEND_MESSAGE = 'actSendMessage'
export const ACT_SEND_CONTACT_MESSAGE = 'actSendContactMessage'
export const ACT_SEND_GEO_MESSAGE = 'actSendGeoMessage'
export const ACT_SEND_POOL_MESSAGE = 'actSendPoolMessage'
export const ACT_SEND_FILE_MESSAGE = 'actSendFileMessage'
export const ACT_SEND_DATA_MESSAGE = 'actSendDataMessage'
export const ACT_SEND_TYPING_EVENT = 'actSendTypingEvent'
export const ACT_LEAVE_FROM_CHAT = 'actLeaveFromChat'
export const ACT_CHAT_SET_MSG_ENTITIES = 'actChatSetMessageEntities'
export const ACT_SET_SELECT_MODE = 'actSetSelectMode'
export const ACT_SET_SELECTED_MSGS = 'actSetSelectedMessages'
export const ACT_DELETE_SELECTED_MSGS = 'actDeleteSelectedMessages'
export const ACT_HANDLE_COMMENT_SUBSCRIBED_EVENT = 'actHandleCommentSubscibedEvent'
export const ACT_HANDLE_COMMENT_EVENT = 'actHandleCommentEvent'
export const ACT_HANDLE_COMMENT_REACTION_EVENT = 'actHandleCommentReactionEvent'
export const ACT_HANDLE_COMMENT_UPDATE_EVENT = 'actHandleCommentUpdateEvent'
export const ACT_HANDLE_COMMENT_CHANGE_EVENT = 'actHandleCommentChangeEvent'
export const ACT_SET_CURRENT_COMMENTS = 'actSetCurrentComments'
export const ACT_SET_MSG_COMMENT_SUBSCRIBED = 'actSetMsgCommentSubscribed'
export const ACT_COMMENT_SUBSCRIBED = 'actCommentSubscribed'
export const ACT_COMMENT_SENT_RECEIVED = 'actCommentSentReceived'
export const ACT_COMMENT_REACTION = 'actCommentReaction'
export const ACT_COMMENT_UPDATE = 'actCommentUpdate'
export const ACT_COMMENT_CHANGE = 'actCommentChange'

/** Bots **/
export const ACT_BOT_ADD = 'actBotAdd'
export const ACT_BOT_REMOVE = 'removeBot'
export const ACT_SEARCH_BOTS = 'actSearchBots'

/** Chat **/
export const ACT_OPEN_CHAT = 'act_open_chat'
export const ACT_CHAT_SCROLL_UP = 'act_chat_scrollUp'
export const ACT_CHAT_UPDATE_EDITED = 'actChatUpdateEdited'
export const ACT_CHAT_UPDATE_REPLY = 'act_update_reply'
export const ACT_CHAT_REMOVE_MSG = 'actChatRemoveMsg'
export const ACT_CHAT_REMOVE_MSGS = 'actChatRemoveMsgs'
export const ACT_CHAT_CHANGE_MSG_TEXT = 'actChatChangeMsg'
export const ACT_CHAT_GET_MESSAGE_BY_ID = 'actChatGetMessageById'
export const ACT_CHAT_SET_SEARCH = 'actChatSetSearch'
export const ACT_CHAT_SET_MEDIA_SEARCH = 'actChatSetMediaSearch'
export const ACT_CHAT_ADD_MESSAGE = 'actChatAddMessage'
export const ACT_CHAT_UPDATE_MESSAGE = 'actChatUpdateMessage'
export const ACT_CHAT_UPDATE_MESSAGES = 'actChatUpdateMessages'
export const ACT_CHAT_SET_MSG_REACTION = 'actChatSetMsgReaction'
export const ACT_CHAT_UPDATE_REACTION = 'actChatUpdateReaction'
export const ACT_CHAT_SHOW_REACTION_PANEL = 'actChatShowReactionPanel'
export const ACT_CHAT_SHOW_REACTION_PICKER = 'actChatShowReactionPicker'
export const ACT_CHAT_GET_MESSAGES = 'actChatGetMessages'
export const ACT_CHAT_GET_MESSAGES_MEDIA = 'actChatGetMessagesMedia'
export const ACT_CHAT_GET_PUBLICATIONS_MEDIA = 'actChatGetPublicationsMedia'
export const ACT_CHAT_GET_SCREEN_MESSAGES = 'actChatGetScreenMessages'
export const ACT_CHAT_GET_PREV_MESSAGES = 'actChatGetPrevMessages'
export const ACT_CHAT_GET_NEXT_MESSAGES = 'actChatGetNextMessages'
export const ACT_CHAT_LOAD_PREV_MESSAGES = 'actChatLoadPrevMessages'
export const ACT_CHAT_LOAD_NEXT_MESSAGES = 'actChatLoadNextMessages'

/** UserData **/
export const ACT_SET_ROSCHAT_SERVER = 'setRoschatServer'
export const ACT_SET_ROSCHAT_SERVER_CONFIG = 'setRoschatServerConfig';
export const ACT_UPDATE_USER_PARAMS = 'updateUserParams'
export const ACT_SET_UID = 'setUid'
export const ACT_SET_LOGIN = 'setLogin'
export const ACT_SET_CURRENT_PANE = 'setCurrentPane'
export const ACT_SET_CONTACT_STATUS = 'actSetContactStatus'
export const ACT_SET_BIRTHDAY_NOTIFY = 'setBirthdayNotify';
export const ACT_SET_SEARCHED_CONTACTS = 'actSetSearchContacts'
export const ACT_CLEAR_SEARCHED_CONTACTS = 'actClearSearchContacts'
export const ACT_SET_SOUNDS = 'actSetSounds'
export const ACT_UPDATE_SOUND = 'actUpdateSound'
export const ACT_GET_SOUND_BLOB = 'actGetSoundBlob'
export const ACT_VOLUME_NOTIFICATION = 'actVolumeNotification'
export const ACT_VOLUME_RING = 'actVolumeRing'
export const ACT_VOLUME_RADIO = 'actVolumeRadio'
export const ACT_VOLUME_AUDIO = 'actVolumeAudio';
export const ACT_WEBCAMERA_ENABLE = 'actWebcameraEnable';
export const ACT_MICROPHONE_ENABLE = 'actMicrophoneEnable';
export const ACT_ACTIVE_SPEAKERS = 'actActiveSpeakers';
export const ACT_ACTIVE_MICROPHONE = 'actActiveMicrophone';
export const ACT_ACTIVE_WEBCAMERA = 'actActiveWebcamera';
export const ACT_VOLUME_MICROPHONE = 'actVolumeMicrophone';
export const ACT_MEDIA_DEVICES = 'actMediaDevices'
export const ACT_GET_SERVER_CONFIG = 'actGetServerConfig'
export const ACT_CHECK_MEDIA_DEVICES = 'actCheckMediaDevice'
export const ACT_CHECK_MEDIA_DEVICE = 'actCheckMicrophone'
export const ACT_REQUEST_MEDIA_DEVICES_ACCESS = 'actRequestMediaDevicesAccess'
export const ACT_REQUEST_MEDIA_DEVICE_ACCESS = 'actRequestMediaDeviceAccess'
export const ACT_SET_MEDIA_DEVICE_LAST_OK = 'actSetMediaDeviceLastOk'
export const ACT_SAVE_INTEGRATION_SETTINGS = 'actSaveIntegrationSettings'
export const ACT_CLEAR_INTEGRATION_SETTINGS = 'actClearIntegrationSettings'
export const ACT_UPDATE_SERVERS_LIST = 'actUpdateServersList'
export const ACT_UPDATE_SERVER_INFO = 'actGetServerInfo'
export const ACT_USERDATA_GET_TURN_SERVER = 'actUserdataGetTurnServer'

/** Acs **/
export const ACT_ACS_UPDATE_CONTACTS = 'updateContacts'
export const ACT_ACS_ADD_RECORD = 'addAcsRecord'
export const ACT_ACS_DELETE_RECORD = 'deleteAcsRecord'
export const ACT_ACS_STOP_UPDATE = 'stopUpdateInterval'
export const ACT_ACS_SET_CID = 'setCid'
export const ACT_ACS_SET_CONTACTS = 'setContacts'
export const ACT_ACS_SET_ROLE = 'actAcsSetRole'
export const ACT_ACS_SET_TYPE = 'setType'
export const ACT_ACS_SET_IS_ADMIN = 'setIsAdmin'
export const ACT_ACS_SET_OM_LOGIN = 'setOmLogin'
export const ACT_ACS_SET_OM_PASSWORD = 'setOmPassword'
export const ACT_ACS_UPDATE_INFO = 'updateInfo'

/** Alarm **/
export const ACT_ALARM_SET_PROPS = 'setAlarmProps'

/** Phone **/
export const ACT_PHONE_CALL = 'setPhoneCall'
export const ACT_EVENT_PHONE = 'setEventPhone'
export const ACT_PHONE_COMMANDS = 'setPhoneCommands'
export const ACT_PHONE_CHANGE_STATE = 'phoneChangeState'
export const ACT_VIDEO_CONF_OPEN = 'actVideoConfOpen'
export const ACT_PHONE_HIDE = 'actPhoneHide'
export const ACT_ASISTANT_OPEN = 'actAsistantOpen'
export const ACT_PHONE_HOLD = 'actPhoneHold'
export const ACT_PHONE_SELECT = 'actPhoneSelect'
export const ACT_PHONE_SET_CALLS_ON_HOLD = 'actPhoneSetCallsOnHold'
export const ACT_PHONE_TERMINATE_CALL = 'actPhoneTerminateCall'
export const START_TIMER = 'startTimer'
export const ACT_CHANGE_VOLUME_EVENT = 'actChangeVolumeEvent'
export const ACT_CHANGE_PERMISSION_DEVICE_EVENT = 'actChangePermissionDeviceEvent'
export const ACT_PHONE_RESIZE = 'actPhoneResize'
export const ACT_PHONE_CALL_CONTINUE = 'actPhoneCallContinue'
export const ACT_PHONE_CREATE_CALL_WORKER = 'actPhoneCreateCallWorker'
export const ACT_PHONE_UPDATE_CALL_CON_STATUS = 'actPhoneUpdateCallConStatus'
export const ACT_PHONE_RTC_CALL = 'actPhoneRtcCall'
export const ACT_PHONE_GET_ICE_SERVERS = 'actPhoneGetIceServers'
export const ACT_PHONE_RTC_CALL_TRANSIT = 'actPhoneRtcCallTransit'
export const ACT_PHONE_RTC_CALL_CONFERENCE = 'actPhoneRtcCallConference'
export const ACT_PHONE_RTC_CALL_CONF_LIST_UPDATE = 'actPhoneRtcCallConfListUpdate'
export const ACT_PHONE_RTC_CALL_DTMF = 'actPhoneRtcCallDtmf'
export const ACT_PHONE_RTC_SET_CALL_STATS = 'actPhoneRtcSetCallStats'
export const ACT_PHONE_RTC_CALL_API = 'actPhoneRtcCallApi'
export const ACT_PHONE_RTC_CALL_ANSWER_API = 'actPhoneRtcCallAnswerApi'
export const ACT_PHONE_RTC_CALL_RINGING_API = 'actPhoneRtcCallRingingApi'
export const ACT_PHONE_RTC_CALL_TERMINATION_API = 'actPhoneRtcCallTerminationApi'
export const ACT_PHONE_RTC_CALL_OPTS_CHANGED_API = 'actPhoneRtcCallChangedApi'
export const ACT_PHONE_RTC_CALL_DTMF_API = 'actPhoneRtcCallDtmfApi'
export const ACT_PHONE_RTC_CALL_TRANSIT_API = 'actPhoneRtcCallTransitApi'
export const ACT_PHONE_RTC_CALL_CONFERENCE_API = 'actPhoneRtcCallConferenceApi'
export const ACT_PHONE_RTC_CALL_CONF_LIST_API = 'actPhoneRtcCallConfListApi'
export const ACT_PHONE_RTC_CALL_SEND_REPORT_API = 'actPhoneRtcCallSendReportApi'
export const ACT_PHONE_RTC_SEND_CALL_PROCESSING = 'actPhoneRtcSendCallProcessing'
export const ACT_PHONE_RTC_CALL_HANDLE_API_CALLS_EVENT = 'actPhoneRtcCallHandleApiCallsEvent'

/** Locations **/
export const ACT_LOCATIONS_UPDATE_CONFIG = 'updateLocationsConfig'
export const ACT_UPDATE_CONTACT_LOCATION = 'updateContactLocation'

/** ClientData **/
export const ACT_GET_NEW_CLIENT_INFO = 'requestUpdateData'
export const ACT_CHECK_NEW_VERSION = 'actCheckNewVersion'
export const ACT_UPDATE_NOTIFICATION = 'actUpdateNotification'
export const ACT_UPDATE_CLIENT = 'updateClient'
export const ACT_CHANGE_LOCALE = 'changeLocale'
export const ACT_SET_CLIENT_INFO = 'setClientInfo'
export const ACT_SET_LAST_TIME_ACTIVE = 'actSetLastTimeActive'
export const ACT_SET_FONTS_SETTINGS = 'actSetFontsSettings'

/** Info **/
export const ACT_INFO_PUSH = 'infoPush'
export const ACT_INFO_REPLACE = 'infoReplace'
export const ACT_INFO_BACK = 'infoBack'
export const ACT_INFO_CLEAR = 'infoClear'
export const ACT_INFO_TOGGLE = 'infoToggle'
export const ACT_SET_COMPACT_MODE = 'actSetCompactMode'

/** Modals **/
export const ACT_MODAL_OPEN = 'modalOpen'
export const ACT_MODAL_CLOSE = 'modalClose'

/** Notification **/
export const ACT_OPEN_DEFAULT_NOTIFICATION = 'actOpenDefaultNotification'
export const ACT_OPEN_CUSTOM_NOTIFICATION = 'actOpenCustomNotification'
export const ACT_OPEN_ELECTRON_NOTIFICATION = 'actOpenElectronNotification'
export const ACT_ADD_NOTIFICATION = 'addNotification'
export const ACT_NOTIFICATION_ELECTRON_STATUS = 'actNotificationElectronStatus'
export const ACT_CLOSE_ELECTRON_NOTIFICATION = 'actCloseElectronNotification'
export const ACT_ONCLICK_NOTIFICATION = 'actOnclickNotification'
export const ACT_CLOSE_DEFAULT_NOTIFICATION = 'actCloseDefaultNotification'
export const ACT_SHOW_SYSTEM_NOTIFICATION = 'actShowSystemNotification'
export const ACT_HIDE_SYSTEM_NOTIFICATION = 'actHideSystemNotification'

/** Radio **/
export const ACT_SET_CURRENT_RADIO = 'actRadioSet'
export const ACT_SET_SPEECH_PACK_RATE = 'actRadioSetSpeechPackRate'
export const ACT_SET_SPEECH_BUFFER_EMPTY = 'actSetSpeechBufferEmpty'
export const ACT_SET_DUPLEX_SPEECH_BUFFER_EMPTY = 'actSetDuplexSpeechBufferEmpty'
export const ACT_SET_PTT_POP_AWAITING = 'actSetPttPopAwaiting'
export const ACT_RADIO_STATE_TOGGLE = 'actRadioStateToggle'
export const ACT_SET_RADIO_SETTINGS = 'actSetRadioSettings'
export const ACT_REQUEST_TOKEN = 'actRequestToken'

/** RcRadio **/
export const ACT_RC_RADIO_CONNECT = 'actRcRadioConnect'
export const ACT_RC_RADIO_GET_PROTO = 'actRcRadioGetProto'
export const ACT_RC_RADIO_DISCONNECT = 'actRcRadioDisconnect'
export const ACT_RC_RADIO_CONNECT_STATE_TOGGLE = 'actRcRadioConnectStateToggle'
export const ACT_RC_RADIO_GET_STATUS = 'actRcRadioGetStatus'
export const ACT_RC_RADIO_REQUEST_VOICE = 'actRcRadioRequestVoice'
export const ACT_RC_RADIO_STOP_VOICE = 'actRcRadioStopVoice'
export const ACT_RC_RADIO_SEND_VOICE = 'actRcRadioSendVoice'
export const ACT_SET_RC_RADIO_CONNECTION_STATUS = 'actSetRcRadioConStatus'
export const ACT_RC_RADIO_SET_ECHO = 'actRcRadioSetEcho'
export const ACT_RC_RADIO_EXIT_BTN = 'actRcRadioExitBtn'
export const ACT_RC_RADIO_HANDLE_STATUS_EVENT = 'actRcRadioHandleStatusEvent'
export const ACT_RC_RADIO_HANDLE_GRANT_EVENT = 'actRcRadioHandleGrantEvent'
export const ACT_RC_RADIO_HANDLE_VOICE_EVENT = 'actRcRadioHandleVoiceEvent'
export const ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT = 'actRcRadioHandleDisconnectEvent'
export const ACT_RC_RADIO_HANDLE_MESSAGE_EVENT = 'actRcRadioHandleMessageEvent'
export const ACT_RC_RADIO_REQUEST_TOKEN = 'actRcRadioRequestToken'


/** Tetra **/
export const ACT_TETRA_CONNECT = 'actTetraConnect'
export const ACT_TETRA_GET_PROTO = 'actTetraGetProto'
export const ACT_TETRA_DISCONNECT = 'actTetraDisconnect'
export const ACT_TETRA_CONNECT_STATE_TOGGLE = 'actTetraConnectStateToggle'
export const ACT_TETRA_GET_STATUS = 'actTetraGetStatus'
export const ACT_TETRA_LINK_CREATE = 'actTetraLinkCreate'
export const ACT_TETRA_START_LINK_TIMER = 'actTetraStartLinkTimer'
export const ACT_TETRA_STOP_LINK_TIMER = 'actTetraStopLinkTimer'
export const ACT_TETRA_LINK_PING = 'actTetraLinkPing'
export const ACT_TETRA_LOCATE = 'actTetraLocate'
export const ACT_TETRA_GET_ATTACH_GROUPS = 'actTetraGetAttachGroups'
export const ACT_TETRA_GET_GROUPS_LIST = 'actTetraGetGroupList'
export const ACT_TETRA_ACTIVATE_GROUP = 'actTetraActivateGroup'
export const ACT_TETRA_PUSH_PTT = 'actTetraPushPTT'
export const ACT_TETRA_POP_PTT = 'actTetraPopPTT'
export const ACT_TETRA_SET_ECHO = 'actTetraSetEcho'
export const ACT_TETRA_EXIT_BTN = 'actTetraExitBtn'
export const ACT_TETRA_SEND_SPEECH = 'actTetraSendSpeech'
export const ACT_TETRA_HANDLE_MSG_EVENT = 'actTetraHandleMsgEvent'
export const ACT_TETRA_HANDLE_STATUS_EVENT = 'actTetraHandleStatusEvent'
export const ACT_TETRA_REQUEST_TOKEN = 'actTetraRequestToken'
export const ACT_TETRA_GET_GROUP_USERS = 'actTetraGetGroupUsers'
export const ACT_TETRA_SEARCH_USERS = 'actTetraSearchUsers'
export const ACT_TETRA_UPDATE_USERS_LIST = 'actTetraUpdateUsersList'
export const ACT_TETRA_ADD_RECENT_USER = 'actTetraAddRecentUser'
export const ACT_TETRA_GET_USER_STATUS = 'actTetraGetUserStatus'
export const ACT_TETRA_MESSAGE = 'actTetraMessage'
export const ACT_TETRA_ADD_MESSAGE = 'actTetraAddMessage'
export const ACT_TETRA_STORE_MESSAGES = 'actTetraStoreMessages'
export const ACT_TETRA_RESTORE_MESSAGES = 'actTetraRestoreMessages'
export const ACT_TETRA_SEARCH_USER_BY_SSI = 'actTetraSearchUserBySsi'
export const ACT_TETRA_API_SEND_LINK_CMD = 'actTetraApiSendLinkCmd'
export const ACT_TETRA_API_SEND_SSI_CMD = 'actTetraApiSendSsiCmd'
export const ACT_TETRA_API_SEND_CMD = 'actTetraApiSendCmd'


export const ACT_TETRA_HANDLE_DUPLEX_CALL_EVENT = 'actTetraHandleDuplexCallEvent'
export const ACT_TETRA_MAKE_DUPLEX_CALL = 'actTetraMakeDuplexCall'
export const ACT_TETRA_TERMINATE_DUPLEX_CALL = 'actTetraTerminateDuplexCall'
export const ACT_TETRA_ANSWER_DUPLEX_CALL = 'actTetraAnswerDuplexCall'
export const ACT_TETRA_END_DUPLEX_CALL = 'actTetraEndDuplexCall'
export const ACT_TETRA_SELECT_DUPLEX_CALL = 'actTetraSelectDuplexCall'

export const ACT_TETRA_TOGGLE_MICROPHONE = 'actTetraToggleMicrophone'
export const ACT_TETRA_TRANSIT = 'actTetraTransit'
export const ACT_TETRA_PAUSE = 'actTetraPause'
export const ACT_TETRA_TERMINATE = 'actTetraTerminate'
export const ACT_TETRA_SET_ACTIVE_CALL = 'actTetraSetActiveCall'

/** VideoConf **/
export const ACT_ADD_CONF = 'actAddConf'
export const ACT_SAVE_REMOTE_PROCESSING = 'actSaveRemoveProcessing'
export const ACT_CONFERENCE_EVENT = 'actConferenceEvent'
export const ACT_TERMINATION_EVENT = 'actVideoConfTerminationEvent'
export const ACT_SEND_OFFER_CONFERENCE = 'actSendOfferConference'
export const ACT_GET_TURN_SERVER = 'actGetTurnServer'
export const ACT_CONFERENCE_RINGING = 'actConferenceRinging'
export const ACT_CONFERENCE_ANSVER = 'actConferenceAnsver'
export const ACT_CONFERENCE_TERMINATION = 'actConferenceTermination'
export const ACT_SYNC_STORE_ELECTRON = 'actSyncStoreElectron'
export const ACT_CHANGE_CONTACT_STATUS = 'actChangeContactStatus'
export const ACT_CONFERENCE_HANDLE_API_CONFERENCE_EVENT = 'actConferenceHandleApiConferenceEvent'
export const ACT_HOLD_CONF ='actHoldConf'
export const ACT_ACTIVATE_CONF = 'actActivateConf'
export const ACT_SAVE_DATA_CONF = 'actSaveDataConf'
export const ACT_ADD_LOCALSTREAM = 'actAddLocalStream'
export const ACT_ADD_PEER_CONNECTION ='actAddPeerConnection'
export const ACT_DELETE_PEER_CONNECTION = 'actDeletePerrConnection'
export const ACT_OPEN_ADD_DIALOG = 'actOpenAddDialog'
export const ACT_SET_ADD_DIALOG_OPENED = 'actSetAddDialogOpened'
export const ACT_ADD_CONTACTS_TO_CONFERENCE = 'actAddContactsToConference'
export const ACT_VM_GET_CONFERENCE_INFO = 'actGetConferenceInfo' 

/** Asistant **/
export const ACT_UPDATE_CALLS = 'actUpdateCalls'
export const ACT_UPDATE_CALL = 'actUpdateCall'
export const ACT_UPDATE_TA = 'actUpdateTA'
export const ACT_GET_TA = 'actGetTA'
export const ACT_GET_CALL_INFO = 'actGetCallInfo'
export const ACT_GET_CALLS_INFO = 'actGetCallsInfo'
export const ACT_ASSISTANT_TERMINATE_CALL = 'actAssistantTerminateCall'
export const ACT_ASSISTANT_TERMINATE_ALL_CALLS = 'actAssistantTerminateAllCalls'

export const ACT_TOGGLE_MICROPHONE = 'actToggleMicrophone'
export const ACT_TOGGLE_SPEAKER = 'actToggleSpeaker'
export const ACT_TOGGLE_DND = 'actToggleDND'
export const ACT_PRESS_DIAL_KEY = 'actPressDialKey'
export const ACT_DIAL_NUMBER = 'actDialNumber'
export const ACT_SET_ACTIVE_CALL = 'actSetActiveCall'
export const ACT_TRANSIT = 'actTransit'
export const ACT_CONFERENCE = 'actConference'
export const ACT_TERMINATE_ASISTANT = 'actCloseAsistant'
export const ACT_SELECT_ASSISTANT = 'actSelectAssistant'
export const ACT_GET_CONTACT = 'ActGetContact'
export const ACT_CALL_ASSISTANT = 'actCallAssistant'
export const ACT_SELECT_TYPE_ASSISTENT = 'actSelectTypeAssistent'
export const ACT_PHONE_SHOW = 'actPhoneShow'
export const ACT_UPDATE_CALL_ASSISTANT = 'actUpdateCallAssistant'
export const ACT_UPDATE_INCOMMING_CALL = 'actUpdateIncommingCall'
export const ACT_CONF_LIST_UPDATE_ASSISTANT = 'actConfListUpdateAssistant'

/**PhoneController**/
export const CALLS_CONTROLLER = 'callsController'
export const CALLS_EVENTS_CONTROLLER = 'callsEventController'
export const ACT_SHOW_INCOMMING_CALL = 'actShowIncommingCall'
export const ACT_HIDE_INCOMMING_CALL = 'actHideIncommingCall'
export const ACT_ADD_INCOMMING_CALL = 'actAddIncommingCall'
export const ACT_ANSWER_INCOMMING_CALL = 'actAnswerIncommingCall'
export const ACT_TERMINATE_ALL_INCOMING_CALLS = 'actTerminateAllIncomingCalls'
export const ACT_TERMINATE_INCOMMING_CALL = 'actTerminateIncommingCall'
export const ACT_ADD_ANSWERED_CALL = "actAddAnsweredCall"
export const ACT_SELECT_CALL = "actSelectCall"
export const ACT_SET_POSITION_ANSWERED_CALL = "actSetPositionAnsweredCall"
export const ACT_TERMINATE_CALL = "actTerminateCall"
export const ACT_HOLD_CALL = "actHoldCall"
export const ACT_DELETE_ANSWERED_CALL = 'actDeleteAnsweredCall'
export const ACT_CHANGE_CONFERENCE_EVENT = 'actChangeConferenceEvent'
export const ACT_TOGGLE_PHONE_MINIMIZE = 'actTogglePhoneMinimize'
export const ACT_MINIMIZE = 'actMinimize'
export const ACT_TOGGLE_PHONE_FULLSCREEN = 'actTogglePhoneFullscreen'
export const ACT_SET_PHONE_FULLSCREEN = 'actSetPhoneFullscreen'
export const ACT_SET_ANSWERED_CALL_STATUS = 'actSetCallTalkStart'
export const ACT_SET_CALL_STATUS = 'actSetCallStatus'
export const ACT_UPDATE_ANSWERED_CALL_ID = 'actUpdateAnsweredCallId'
export const ACT_SUBSCRIBE_ON_FREE_REQUEST = 'actSubscribeOnFreeRequest'
export const ACT_CALL_CONTINUE = 'actСallСontinue'
export const ACT_SUBSCRIBE_AVAILABILITY = 'actSubscribeAvailability'
export const ACT_GET_RTC_CALL_AVAILABILITY = 'actGetRtcCallAvailability'
export const ACT_SET_CALL_PARTICIPANTS = 'actSetCallParticipants'
export const ACT_SET_ON_HOLD_OTHER_CALLS = 'actSetOnHoldOtherCalls'
export const ACT_SET_CALL_MIC_MUTE_STATUS = 'actSetCallMicMuteStatus'

/** AJAX **/
export const ACT_ABORT_AJAX_CALL = 'abortAJAXCall'
export const ACT_ADD_AJAX_CALL = 'actAddAJAXCall'
export const ACT_UPDATE_AJAX_CALL_PROGRESS = 'actUpdateAJAXCallProgress'
export const ACT_AJAX_SEND_FILE = 'actAjaxSendFile'
export const ACT_AJAX_GET_FILE = 'actAjaxGetFile'

/** BLOB **/
export const ACT_BLOB_ADD_FILE = 'actBlobAddFile'
export const ACT_GET_FILE_FROM_BLOB_URL = 'actGetFileFromBlobUrl'

/** UserProfile **/
export const ACT_GET_USER_PROFILE = 'actGetUserProfile'
export const ACT_SET_USER_PROFILE = 'actSetUserProfile'
export const ACT_SET_USER_PROFILE_REVISION = 'actSetUserProfileRevision'
export const ACT_SET_DND = 'setDnd'
export const ACT_SET_CONFSCALENDAR = 'actSetConfsCalendar'
export const ACT_DELETE_CONFSCALENDAR = 'actDeleteConfsCalendar'

/** VideoConfNew **/
export const ACT_VC_CONF_CREATE = 'actVcConfCreate'
export const ACT_VC_CONF_CHANGE = 'actVcConfChange'
export const ACT_VC_CONF_START = 'actVcConfStart'
export const ACT_VC_CONF_DELETE = 'actVcConfDelete'
export const ACT_VC_GET_CONF_LIST = 'actVcGetConfList'
export const ACT_VC_GET_CONF_INFO = 'actVcGetConfInfo'
export const ACT_VC_CONF_ADD = 'actVcConfAdd'
export const ACT_VC_CONF_REMOVE = 'actVcConfRemove'
export const ACT_VC_TOGGLE_MINIMIZE = 'actVcToggleMinimize'
export const ACT_VC_MINIMIZE = 'actVcMinimize'
export const ACT_VC_TOGGLE_FULLSCREEN = 'actVcToggleFullscreen'
export const ACT_VC_SET_FULLSCREEN = 'actVcSetFullscreen'
export const ACT_VC_CONF_SET_ICE = 'actVcConfSetIce'
export const ACT_VC_CONF_JOIN = 'actVcConfJoin'
export const ACT_VC_CONF_JOIN_BY_LINK = 'actVcConfJoinByLink'
export const ACT_VC_CONF_FINISH = 'actVcConfFinish'
export const ACT_VC_CONF_LEAVE = 'actVcConfLeave'
export const ACT_VC_CONF_MINIMIZE = 'actVcConfMinimize'
export const ACT_VC_CONF_CAMERA_TOGGLE = 'actVcConfCameraToggle'
export const ACT_VC_CONF_MICROPHONE_TOGGLE = 'actVcConfMicrophoneToggle'
export const ACT_VC_CONF_SPEAKER_TOGGLE = 'actVcConfSpeakerToggle'
export const ACT_VC_CONF_WANT_SPEAK = 'actVcConfWantSpeak'
export const ACT_VC_CONF_SET_MEMBER_PROPERTY = 'actVcConfSetMemberProperty'
export const ACT_VC_CONF_SET_MEMBER_MODERATOR = 'actVcConfSetMemberModerator'
export const ACT_VC_CONF_SET_MEMBER_MIC = 'actVcConfSetMemberMic'
export const ACT_VC_CONF_SET_MEMBER_VIDEO = 'actVcConfSetMemberVideo'
export const ACT_VC_CONF_BAN_MEMBER = 'actVcConfBanMember'
export const ACT_VC_CONF_KICK_OUT_MEMBER = 'actVcConfKickOutMember'
export const ACT_VC_CONF_SHARE = 'actVcConfShare'
export const ACT_VC_CONF_HAND_UP = 'actVcConfHandUp'
export const ACT_VC_CONF_REACTION = 'actVcConfShareReaction'
export const ACT_VC_CONF_OPEN_DIALOG = 'actVcConfOpenDialog'
export const ACT_VC_CONF_SET_DIALOG_OPENED = 'actVcConfSetDialogOpened'
export const ACT_VC_CONF_SEND_INVITE = 'actVcConfSendInvite'
export const ACT_VC_CONF_SET_DEVICE = 'actVcConfSetDevice'

/** VideoConfAbstract **/
export const ACT_CONF_CREATE = 'actConfCreate'
export const ACT_CONF_CHANGE = 'actConfChange'
export const ACT_CONF_START = 'actConfStart'
export const ACT_CONF_DELETE = 'actConfDelete'
export const ACT_GET_CONF_LIST = 'actGetConfList'
export const ACT_GET_CONF_INFO = 'actGetConfInfo'
export const ACT_CONF_JOIN = 'actConfJoin'
export const ACT_CONF_JOIN_BY_LINK = 'actConfJoinByLink'
export const ACT_CONF_FINISH = 'actConfFinish'
export const ACT_CONF_LEAVE = 'actConfLeave'
export const ACT_CONF_MINIMIZE = 'actConfMinimize'
export const ACT_CONF_CAMERA_TOGGLE = 'actConfCameraToggle'
export const ACT_CONF_MICROPHONE_TOGGLE = 'actConfMicrophoneToggle'
export const ACT_CONF_SPEAKER_TOGGLE = 'actConfSpeakerToggle'
export const ACT_CONF_CHANGE_MEMBER_PROPS = 'actConfChangeMemberProps'
export const ACT_CONF_WANT_SPEAK = 'actConfWantSpeak'
export const ACT_CONF_SET_MEMBER_PROPERTY = 'actConfSetMemberProperty'
export const ACT_CONF_SET_MEMBER_MODERATOR = 'actConfSetMemberModerator'
export const ACT_CONF_SET_MEMBER_MIC = 'actConfSetMemberMic'
export const ACT_CONF_SET_MEMBER_VIDEO = 'actConfSetMemberVideo'
export const ACT_CONF_BAN_MEMBER = 'actConfBanMember'
export const ACT_CONF_KICK_OUT_MEMBER = 'actConfKickOutMember'
export const ACT_CONF_SHARE = 'actConfShare'
export const ACT_CONF_SEND_CID = 'actConfSendCid'
export const ACT_CONF_ANSWER_CID = 'actConfAnswerCid'
export const ACT_CONF_HAND_UP = 'actConfHandUp'
export const ACT_CONF_REACTION = 'actConfReaction'
export const ACT_CONF_SET_DEVICE = 'actConfSetDevice'
export const ACT_CONF_SEND_INVITE = 'actConfSendInvite'
export const ACT_CONF_SET_DEVICES = 'actConfSetDevices'

/** VideoConfVideomost **/
export const ACT_VM_CONF_FINISH = 'actVmConfFinish'
export const ACT_VM_CONF_GET_SID = 'actVmConfGetSid'
export const ACT_VM_CONF_SEARCH_CONFERENCE = 'actVmConfSearchConference'
export const ACT_VM_CONF_SET_PARAMS = 'actVmConfSetParams'
export const ACT_VM_CONF_DELETE = 'actVmConfDelete'
export const ACT_VM_CONF_HANDLE_MEMBER_DATA = 'actVmConfHandleMemberData'
export const ACT_VM_CONF_UPDATE_DEVICES = 'actVmConfUpdateDevices'

/** VideoConfRoschat **/
export const ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT = 'actRoschatConfApiCreateTransport'
export const ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT = 'actRoschatConfApiDestroyTransport'
export const ACT_ROSCHAT_CONF_API_MEETING_START = 'actRoschatConfApiMeetingStart'
export const ACT_ROSCHAT_CONF_API_MEETING_STOP = 'actRoschatConfApiMeetingStop'
export const ACT_ROSCHAT_CONF_API_MEETING_INFO = 'actRoschatConfApiMeetingInfo'
export const ACT_ROSCHAT_CONF_API_MEETING_ENTER = 'actRoschatConfApiMeetingEnter'
export const ACT_ROSCHAT_CONF_API_MEETING_EXIT = 'actRoschatConfApiMeetingExit'
export const ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER = 'actRoschatConfApiSendMeetingOffer'
export const ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE = 'actRoschatConfApiSendMeetingAddCandidate'
export const ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE = 'actRoschatConfApiSendMeetingRemoveCandidate'

export const ACT_ROSCHAT_CONF_HANDLE_API_MEETING_EVENT = 'actRoschatConfHandleApiMeetingEvent'
export const ACT_ROSCHAT_CONF_HANDLE_ERROR_MEETING_EVENT = 'actRoschatConfHandleErrorMeetingEvent'

export const ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER = 'actRoschatConfApiChangeMeetingUser'
export const ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER = 'actRoschatConfApiDeleteMeetingUser'
export const ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS = 'actRoschatConfApiMeetingInviteUsers'
export const ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER = 'actRoschatConfApiMeetingRejectUser'
export const ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND = 'actRoschatConfApiSendMeetingCommand'
export const ACT_ROSCHAT_CONF_API_SHARING_START = 'actRoschatConfApiSendSharingStart'
export const ACT_ROSCHAT_CONF_API_SHARING_STOP = 'actRoschatConfApiSendSharingStop'
export const ACT_ROSCHAT_CONF_API_GET_ICE_SERVERS = 'actRoschatConfApiGetIceServers'

/** Electron **/
export const ACT_ELECTRON_ASYNC_ACT_CALL = 'actElectronAsyncActCall'
export const ACT_ELECTRON_SYNC_MODULE_STATE_REQUEST = 'actElectronSyncModuleStateRequest'
export const ACT_ELECTRON_SYNC_MODULE_STATE_RESPONSE = 'actElectronSyncModuleStateResponse'

/** DLP **/
export const ACT_SEND_DLP_EVENT = 'actSendDlpEvent'
export const ACT_SEND_SCREENSHOT_EVENT = 'actSendScreenshotEvent'
export const ACT_SEND_FILE_SAVE_EVENT = 'actSendFileSaveEvent'
export const ACT_SEND_CLIPBOARD_EVENT = 'actSendClipboardEvent'
export const ACT_SEND_SHARE_EVENT = 'actSendShareEvent'

/** DATA_MESSAGE_BUILDER **/
export const ACT_BUILD_TEXT_MESSAGE = 'actBuildContactMessage'
export const ACT_BUILD_CONTACT_MESSAGE = 'actBuildContactMessage'
export const ACT_BUILD_GEO_MESSAGE = 'actBuildGeoMessage'
export const ACT_BUILD_POOL_MESSAGE = 'actBuildPoolMessage'
export const ACT_BUILD_FILE_MESSAGE = 'actBuildFileMessage'
export const ACT_BUILD_IMAGE_MESSAGE = 'actBuildImageMessage'
export const ACT_BUILD_VIDEO_MESSAGE = 'actBuildVideoMessage'
export const ACT_BUILD_AUDIO_MESSAGE = 'actBuildAudioMessage'
export const ACT_BUILD_REGULAR_FILE_MESSAGE = 'actBuildRegularFileMessage'

/** Integrations **/
export const ACT_SCHEDULE_CREDENTIALS_CHECK = 'actScheduleCredentialsCheck'
export const ACT_SCHEDULE_DELETE_CREDENTIALS = 'actScheduleDeleteCredentials'
export const ACT_SCHEDULE_UPDATE_CREDENTIALS = 'actScheduleUpdateCredentials'
export const ACT_SCHEDULE_SYNC_APPOINTMENTS = 'actScheduleSyncAppointments'
export const ACT_SCHEDULE_ADD_APPOINTMENT = 'actScheduleAddAppointment'
export const ACT_SCHEDULE_EDIT_APPOINTMENT = 'actScheduleEditAppointment'
export const ACT_SCHEDULE_DELETE_APPOINTMENT = 'actScheduleDeleteAppointment'
export const ACT_SCHEDULE_GET_APPOINTMENT = 'actScheduleGetAppointment'
export const ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS = 'actScheduleGetCalendarAppointments'
export const ACT_SCHEDULE_RESPONSE_TO_INVITATION = 'actScheduleResponseToInvitation'

/** Calls **/
export const ACT_UPDATE_CALLS_AFTER_CONTACT_DELETED = 'actUpdateCallsAfterContactDeleted'
export const ACT_HANDLE_HISTORY_CALL_EVENT = 'actHandleHistoryCallEvent'
export const ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT = 'actHandleHistoryCallChangeEvent'
