var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-header-info",
      attrs: { id: "threads-main-header-info" }
    },
    [
      _c(
        "div",
        {
          staticClass: "header-text",
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "header-user-block",
              on: {
                mouseover: function($event) {
                  return _vm.headerMouseOver($event)
                }
              }
            },
            [
              _c("div", { staticClass: "contact-text-wrapper header-main" }, [
                _c("div", { staticClass: "contact-name-block" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "fiospan" }, [
                      _vm._v(_vm._s(_vm.$t("chat.comments")))
                    ]),
                    _c("span", {
                      staticClass: "sub-text",
                      domProps: { innerHTML: _vm._s(_vm.msg) }
                    })
                  ])
                ])
              ])
            ]
          )
        ]
      ),
      _c("div", { staticClass: "header-btns contact-buttons-block" }, [
        _c("div", {
          staticClass: "circle-animation header-chat-search btn-custom",
          attrs: { title: _vm.$t("search-in-chat") },
          on: { click: _vm.openSearchChat }
        }),
        _c(
          "div",
          {
            staticClass: "circle-animation bell btn-custom",
            attrs: { title: _vm.getTitle },
            on: {
              click: function($event) {
                _vm.isCommentsSubscribed
                  ? _vm.subscribeToComments(false)
                  : _vm.subscribeToComments(true)
              }
            }
          },
          [
            _vm.isCommentsSubscribed
              ? _c("i", { staticClass: "fas fa-bell" })
              : _c("i", { staticClass: "fal fa-bell-slash" })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }