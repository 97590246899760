//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from '../../../../vuex'
import { CHAT, INFO } from '../../../store/modulesNames'
import { GET_INFO_OPEN } from '../../../store/gettersTypes'
import { ACT_CHAT_SET_MSG_REACTION, ACT_CHAT_SHOW_REACTION_PICKER } from '../../../store/actionsTypes'

import emojiData from 'emoji-mart-vue-fast/data/apple.json'
import { EmojiIndex } from 'emoji-mart-vue-fast'
const emojiIndex = new EmojiIndex(emojiData)
const maxRecentEmojis = 6

export default {
    name: "ReactionsPicker",
    props: {
        msgId: {
            type: Number,
            default: -1
        },
        pickerStyle: { type: Object },
        isLeftSide: {
            type: Boolean,
            default: false
        },
        isEmojiPicker: {
            type: Boolean,
            default: false
        }
    },
    created() {
        const recentEmoObj = JSON.parse(localStorage.getItem('rcemoji.frequently'))
        if (!recentEmoObj) return
        const recentEmosStored = Object.fromEntries(Object.entries(recentEmoObj).sort(([,a],[,b]) => b-a))
        Object.keys(recentEmosStored).filter((k,i) => i < maxRecentEmojis).forEach((key, i) => {
            const recentEmo = emojiIndex.findEmoji(key)
            const count = recentEmosStored[key]
            this.reactionEmos[i] = { reaction: recentEmo.native, count}
        })
    },
    data() {
        return {
            reactionEmos: [
                { reaction: '❤️', count: 1 },
                { reaction: '😂', count: 1 },
                { reaction: '😡', count: 1 },
                { reaction: '👍', count: 1 },
                { reaction: '👎', count: 1 },
                { reaction: '🔥', count: 1 },
                { reaction: '✅', count: 1 },
            ],
            isMouseOver: false,
            isShowToolbar: false,
            isMouseOverToolbar: false,
            toolbarX: -1,
            toolbarY: -1,
            pickerX: -1,
            pickerY: -1,
        }
    },
    computed: {
        reactionToolbarStyle() {
            const style = {'transform': 'translateY(-2.0em)'}
            const isInfoPanelAndLeft = this.infoPanelOpened && this.isLeftSide
            if (isInfoPanelAndLeft) this.toolbarX += -85
            Object.assign(style, {'top': this.toolbarY + 'px'})
            Object.assign(style, {'left': this.toolbarX + 'px'})
            return style
        },
        emojiPickerStyle() {
            if (this.pickerX < 0 || this.pickerY < 0) return
            let x = this.pickerX + 'px'
            let y = this.pickerY + 'px'
            const style = {'position': 'fixed', 'top': y, 'left': x, 'max-height': `250px`}
            return style
        },
        infoPanelOpened() {
            return this[GET_INFO_OPEN]
        },
        ...mapGetters(INFO, [GET_INFO_OPEN])
    },
    methods: {
        mouseOver(e) {
            e.stopPropagation = true
            let screenX = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            if (!this.isMouseOver) {
                this.toolbarX = (e.clientX + 200) < screenX ? e.clientX - 130 : e.clientX - 204
                this.toolbarY = e.clientY + 3
            }
            this.isMouseOver = true
            this.$emit('mouseOverReactonPicker', true)
            setTimeout(()=>{
                if (this.isMouseOver) this.isShowToolbar = true
            }, 1000)
        },
        mouseLeave(e) {
            e.stopPropagation = true
            if (!this.isMouseOverToolbar) {
                this.isMouseOver = false
                this.$emit('mouseOverReactonPicker', this.isMouseOver || this.isEmojiPicker)
            }
        },
        mouseOverToolbar(e) {
            this.isMouseOverToolbar = true
        },
        mouseLeaveToolbar() {
            this.isMouseOverToolbar=false
            this.isShowToolbar = false
        },
        getAppleEmoji(reaction) {
            return this.wrapEmoji(reaction)
        },
        showEmojiPicker(e) {
            e.preventDefault()
            e.stopPropagation()
            let screenX = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            let screenY = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            this.pickerY = (screenY - e.clientY > 300) ? e.clientY : e.clientY - 250
            this.pickerX = (screenX - e.clientX > 260) ? e.clientX - 130 : screenX - 294
            this.$nextTick(()=> {
                const style = this.emojiPickerStyle
                this[ACT_CHAT_SHOW_REACTION_PICKER]({ isShow: true, msgId: this.msgId, style})
            })
        },
        onAddBaseReaction(reaction) {
            this[ACT_CHAT_SET_MSG_REACTION]({ id: this.msgId, reaction })
        },
        ...mapActions(CHAT, [ACT_CHAT_SET_MSG_REACTION, ACT_CHAT_SHOW_REACTION_PICKER])
    },

}
