//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { USERDATA, CHAT, INFO } from "../../store/modulesNames"
import { GET_UID, GET_CHAT_SEARCH_STRING } from "../../store/gettersTypes"
import { ACT_INFO_CLEAR, ACT_CHAT_GET_MESSAGES } from "../../store/actionsTypes"

import moment from "moment"

import InfoMainBlock from "./InfoMainBlock.vue"
import IconImage from "../IconImage.vue"
import ListItem from "../custom/ListItem.vue"
import ContentLoader from "../main/body/MainContentLoader.vue"

import {CONTACT_FIELD_TYPES} from '../../constants'
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import { getIsFullCompactMode, getIsCompactMode, setMainVisible } from "../../../ext/trackResizes"

const defaultMessagesCount = 500
const minFoundCountDelta = 15
const excerptLength = 30

const checkIsScrollVisible = () => {
    let isVisible = false
    return new Promise((resolve) => {
        setTimeout(() => {
            const elem = document.getElementById("chat-search-area")
            if (elem) {
                isVisible = elem.scrollHeight > elem.clientHeight
                resolve(isVisible)
            } else resolve(false)
        }, 100)
    })
}

export default {
    name: "SearchInChat",
    mixins: [ScrollToMessageMixin],
    components: {
        IconImage,
        ListItem,
        InfoMainBlock,
        ContentLoader
    },
    props: ["params", "canBack"],
    mounted() {
        this.$nextTick(() => {
            const input = document
                .getElementsByClassName("search-input-wrapper show")[0]
                .getElementsByTagName("input")[0]
            input && input.focus()
        })
    },
    data() {
        return {
            chats: [],
            foundChats: [],
            foundChatsDelta: 0,
            searchString: "",
            chunkStartId: 0,
            firstSearch: true,
            noRecords: this.$t("media-search.messages-not-found"),
            isLookingFor: false,
            isToBeProceed: false,
            itemSize: 71,
            isSearchMore: true,
            isScrollVisible: false,
            isNewSearch: false,
            mediaTypes: { 
                image: this.$t("media-search.image"),
                video: this.$t("media-search.video"),
                audio: this.$t("media-search.audio"),
                file: this.$t("file"),
                link: this.$t("media-search.link") 
            },
        }
    },
    computed: {
        cid() {
            return this[GET_UID]
        },
        bufferSize() {
            return this.itemSize * 10
        },
        scrollerStyle() {
            return this.foundChats.length ? "height: 100%;" : ""
        },
        getSearchString() {
            return this[GET_CHAT_SEARCH_STRING]
        },
        isLoader() {
            return this.searchString.length && (this.isToBeProceed || this.isLookingFor)
        },
        isNoResults() {
            return (
                !this.firstSearch &&
                !this.isLookingFor &&
                !this.isToBeProceed &&
                !this.foundChats.length &&
                this.searchString.length > 1
            )
        },
        showSearchMore() {
            return !this.isLookingFor && this.isScrollVisible && this.isSearchMore
        },
        btnWrapperStyle() {
            return this.foundChats.length ? 'border-width: 1px': 'border-width: 0px'
        },
        ...mapGetters(USERDATA, [GET_UID]), 
        ...mapGetters(CHAT, [GET_CHAT_SEARCH_STRING]),
    },
    methods: {
        getPhoto(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).photo || ""
        },
        getFio(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).fio || ""
        },
        getMessageItem(message) {
            let out =
                message.type === "out" || app.getUid() === message.senderId
            return {
                id: message.id,
                cid: message.cid,
                cidType: message.cidType,
                senderId: message.senderId,
                type: message.dataType,
                sub_type: message.data && message.data.type,
                date: this.formatTime(message.time),
                time: date_helper.secondsLeftToTimeFormat(message.time),
                msg: this.getItemText(message),
                ownMsg: out
            }
        },
        getItemText(message) {
            let text
            switch (message.dataType) {
                case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                    text = getDataText(message.data, this.searchString, this.mediaTypes)
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                    text = ""
                    break
                default:
                    text = ""
            }
            return text
        },
        formatTime(timeCall) {
            let result
            let timeCallFormat = moment().subtract(timeCall, "seconds")
            let today = moment().startOf("day")
            let yesterday = moment()
                .subtract(1, "days")
                .startOf("day")
            let twoDays = moment()
                .subtract(2, "days")
                .startOf("day")
            if (timeCallFormat.isAfter(today)) {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("HH:mm")
            } else if (
                timeCallFormat.isBefore(today) &&
                timeCallFormat.isAfter(yesterday)
            ) {
                result = this.$t("channel-comp.yesterday")
            } else if (
                timeCallFormat.isBefore(yesterday) &&
                timeCallFormat.isAfter(twoDays)
            ) {
                result = this.$t("channel-comp.before-yest")
            } else {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("DD MMM YYYY")
            }
            return result
        },
        async addMessages() {
            let oldestMessage = this.chats.pop()
            let isScroll = await checkIsScrollVisible()
            if (!this.isLookingFor) this.isLookingFor = true
            let params = {
                count: defaultMessagesCount,
                cid: this.params.cid,
                cidType: this.params.cidType,
                startId: oldestMessage ? oldestMessage.id : 0
            }
            if ('commentId' in this.params) params.commentId = this.params.commentId
            let chatsChunk = []
            if (this.isNewSearch || (oldestMessage && this.chunkStartId !== oldestMessage.id)) {
                chatsChunk = await this[ACT_CHAT_GET_MESSAGES](params)
            }

            const foundChatsLengthBefore = this.foundChats.length
            let concatArr = []
            if (chatsChunk.length) { 
                concatArr = this.chats.concat(chatsChunk.filter(elem => this.chats.indexOf(elem) < 0))
                oldestMessage = concatArr[concatArr.length - 1]
                if (oldestMessage && this.chunkStartId === oldestMessage.id) {
                    this.chunkStartId = 0
                    this.isSearchMore = false
                    this.isLookingFor = false
                    this.isToBeProceed = false
                } else {
                    this.chunkStartId = oldestMessage ? oldestMessage.id : 0
                    this.chats = concatArr  
                    this.isNewSearch = false
                    await this.searchMessages()
                    this.foundChatsDelta += this.foundChats.length - foundChatsLengthBefore
                    this.isToBeProceed = this.foundChatsDelta < minFoundCountDelta
                    isScroll = await checkIsScrollVisible()
                    if (!isScroll || this.isToBeProceed) {
                        if (!this.isLookingFor) {
                            this.isLookingFor = true
                            await this.addMessages()
                        }
                    } else if (!this.isToBeProceed) {
                        this.foundChatsDelta = 0
                        this.isLookingFor = false
                        this.isSearchMore = true
                    }
                }
            } else {
                if (oldestMessage && this.chunkStartId !== oldestMessage.id) await this.addMessages()
                else {
                    this.isToBeProceed = false
                    this.isLookingFor = false
                }
            }
        },
        async searchMessages(val = this.searchString) {
            if (this.isNewSearch) this.foundChats.splice(0, this.foundChats.length)
            const chatsLengthBefore = this.chats.length
            const foundChatsLengthBefore = this.foundChats.length
            if (!this.chats.length || this.isNewSearch) {
                this.isLookingFor = true
                let params = {
                    count: defaultMessagesCount,
                    cid: this.params.cid,
                    cidType: this.params.cidType
                }
                if ('commentId' in this.params) params.commentId = this.params.commentId
                this.chats = await this[ACT_CHAT_GET_MESSAGES](params)
                this.isNewSearch = false
            }
            this.chats.forEach(chMsg => {
                this.firstSearch = false
                let msgItem = this.getMessageItem(chMsg)
                let index = -1, msg, excerptDelta = 7
                if (msgItem && msgItem.msg) {
                    msg = msgItem.msg
                    if (typeof msg === "string")
                        index = msg.toLowerCase().indexOf(val.toLowerCase())
                }
                if (index > -1) {
                    const isStart = (index - excerptDelta <= 0) 
                    const newIndex = isStart ? 0 : index - excerptDelta
                    let excerptStart = isStart ? msg.slice(0, index) : "..." + msg.slice(newIndex - 1, index)
                    let emojiIndexes = this.getEmojisIndexes(msg)
                    let endIndex = index + excerptLength
                    const emojiInRange = emojiIndexes.find(emo => emo.offset === endIndex - 1)
                    if (emojiInRange) endIndex = endIndex + 2
                    let excerpt = excerptStart + msg.slice(index, endIndex)
                    const foundInMedia = this.mediaTypes[msgItem.sub_type]
                    if (foundInMedia) excerpt = foundInMedia + ": " + excerpt
                    let highlightString = msg.slice(index, index + val.length)
                    msgItem.excerpt = excerpt
                    msgItem.highlights = highlightString
                    const isItemExist = msgItem.id && this.foundChats.some(i => i.id === msgItem.id)
                    if (!isItemExist) {
                        this.foundChats.push(msgItem)
                    }
                }
            })
            const isScroll = await checkIsScrollVisible()
            this.isScrollVisible = isScroll
            this.isToBeProceed = !this.foundChats.length || (this.foundChats.length > foundChatsLengthBefore && 
                this.foundChats.length - foundChatsLengthBefore < minFoundCountDelta) || 
                (this.chats.length && this.chats.length === chatsLengthBefore)
            if (!isScroll && this.isToBeProceed) {
                await this.addMessages()
            } else {
                if (this.isToBeProceed) {
                    await this.addMessages()
                } else {
                    this.isLookingFor = false
                }
            }
        },
        onContextMenu({ e, message }) {
            let handlers = []
            if (message.id) {
                handlers.push({
                    item_name: this.$t('media-search.goto-chat'),
                    handler: this.scrollToMessage,
                    data: message.id
                })
            }
            handlers.push({
                item_name: this.$t('media-search.forward'),
                handler: this.forwardMessage,
                data: message
            })
            if (message.id) {
                handlers.push({
                    item_name: this.$t('media-search.delete'),
                    handler: this.removeMsg,
                    data: message
                })
            }
            this.cmOpen(e, handlers, "right-bottom")
        },
        scrollToMessage(id, commentId = this.params.commentId) {
            const isCompactMode = getIsFullCompactMode() || getIsCompactMode()
            if (isCompactMode) {
                this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
                setMainVisible()
                if (id) setTimeout(() => this.scrollingToElement(id, commentId), 300)
            }
            else if (id) this.scrollingToElement(id, commentId)
        },
        ...mapActions(INFO, [ACT_INFO_CLEAR]),
        ...mapActions(CHAT, [ACT_CHAT_GET_MESSAGES]),
    },
    watch: {
        getSearchString(newVal) {
            if (!newVal) {
                newVal = ''
                this.isLookingFor = false
            } else {
                this.isNewSearch = true
                this.searchString = newVal
            }
        },
        searchString(val) {
            if (val && val.length >= 2) {
                if (!this.isLookingFor) this.searchMessages(val)
            } 
        }
    }
}

function getDataText(data, searchString) {
    let text = ''
    let fileName = data && data.hasOwnProperty("name") ? data.name + '.' + data.extension + ' ' : data.extension || ''
    const foundFileName = fileName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    const foundFileText = data.text && data.text.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            const fields = Array.isArray(data.fields) && data.fields
            const phones =
                fields &&
                fields.filter(f => f.type === CONTACT_FIELD_TYPES.PHONE || f.type === CONTACT_FIELD_TYPES.WORKPHONE)
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
            const mail = mails && mails.length && mails[0].value
            const fio = (fields && fields.fio) || phone || mail
            text = fio
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = data.longitude + " " + data.latitude
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            if (foundFileName) text = fileName + '\n'
            if (foundFileText) text += data.text + '\n'
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = data.topic
            break
        // case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
        //     text = data
        //     break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = ""
    }
    return text
}
