var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "threads-wrapper" }, [
    _c(
      "div",
      { staticClass: "threads-content" },
      [
        _vm.showContentLoader ? _c("content-loader") : _vm._e(),
        _vm._l(_vm.comments, function(value, name, index) {
          return _c(
            "div",
            { staticClass: "comment-wrapper" },
            [
              _c("div", { staticClass: "comment-date" }, [
                _vm._v(_vm._s("" + name))
              ]),
              _vm._l(_vm.comments[name], function(m, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "comment-msg",
                    class: { in: m.cid !== _vm.myCid, out: m.cid === _vm.myCid }
                  },
                  [
                    m.cid !== _vm.myCid
                      ? _c("icon-image", {
                          staticClass: "small-medium",
                          attrs: { src: m.photo, text: m.fio }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "msg" }, [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s("" + m.comment))
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s("" + m.time))
                      ])
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        }),
        _c("custom-textarea", {
          key: "comments_aka_threads",
          ref: "custom_textarea_comments",
          attrs: {
            id: "comment-input",
            custom_placeholder: _vm.focusedInput
              ? ""
              : _vm.$t("chat.enter-comment"),
            isFormatter: false
          },
          on: {
            textupdated: function(text) {
              return (_vm.input = text)
            },
            focus: _vm.setInputFocus,
            send: _vm.sendComment
          }
        }),
        _c(
          "div",
          {
            staticClass: "buttons send-message",
            class: { "disable-btn": !_vm.input.length },
            attrs: { title: _vm.$t("chat.send-comment") },
            on: { click: _vm.sendComment }
          },
          [_c("i", { staticClass: "fa fa-paper-plane" })]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }