//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { USERDATA, CHAT, INFO, CONTACTS } from "../../store/modulesNames"
import { GET_UID, GET_MERGED_CONTACT_BY_ID, GET_SERVER_REMOTE_HOST } from "../../store/gettersTypes"
import { ACT_INFO_CLEAR } from "../../store/actionsTypes"

import ContentLoader from "../main/body/MainContentLoader.vue"
import InfoMainBlock from "./InfoMainBlock.vue"
import ListItem from "../custom/ListItem.vue"
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import checkMessageDataExist from "../chat/checkMessageDataExist"
import SelectChatToForward from  '../modal/SelectChatToForward.vue'
import DelChatMessage from "../modal/DelChatMessage.vue"
import CustomTextarea from '../custom/CustomTextarea.vue'

import moment from "moment"

import { getIsFullCompactMode, getIsCompactMode } from '../../../ext/trackResizes'
import {CONTACT_FIELD_TYPES} from '../../constants'

export default {
    name: "MediaSearch",
    components: {
        CustomTextarea,
        ListItem,
        InfoMainBlock,
        ContentLoader,
        DelChatMessage
    },
    props: ["params", "canBack"],
    mixins: [ScrollToMessageMixin, checkMessageDataExist],
    created() {
        this.$bus.$on('compact-mode', (val) => {
            this.isCompactMode = val
            this.isFullCompactMode = false
        })
        this.$bus.$on('full-compact-mode', (val) => {
            this.isFullCompactMode = val
            this.isCompactMode = false
        })
    },
    mounted() {
        // this.isScrollVisible = checkIsScrollVisible()
        this.formComments()
    },
    data() {
        return {
            input: '',
            focusedInput: false,
            chatPlaceHolder: this.$t('chat.enter-msg'),
            itemSize: 71,
            isScrollVisible: false,
            isShowSearchMore: true,
            isFullCompactMode: getIsFullCompactMode(),
            isCompactMode: getIsCompactMode(),
            comments: {},
            commentsMockData: [
                {
                    cid: 2,
                    comment: 'bla djfhgjf hjdkfhg hkdjhfgk bla bla 21',
                    time: 1724938155000
                },
                {
                    cid: 3,
                    comment: `bla bla bla fghfdjg fjgkljdfkgjfdg jkdfgjldfg 31`,
                    time: 1724948955000
                },
                {
                    cid: 2,
                    comment: `bla bla bla 22`,
                    time: 1725025755000
                },
                {
                    cid: 4,
                    comment: 'bla bla bla 41',
                    time: 1725184155000
                },
                {
                    cid: 4,
                    comment: 'bla bla bla 42',
                    time: 1725295755000
                },
                {
                    cid: 3,
                    comment: 'bla bla bla 32',
                    time: 1725295785000
                },
            ],
        }
    },
    computed: {
        myCid() {
            console.log("🚀 ~ file: Threads.vue:126 ~ myCid ~ this[GET_UID]:", this[GET_UID])
            return this[GET_UID]
        },
        isChannel() {
            return !!this.params.chId
        },
        bufferSize() {
            return this.itemSize * 10
        },
        defaultTabValue() {
            return this.params && this.params.tabIndexValue
        },
        scrollerStyle() {
            return this.foundChats.length ? "height: 100%" : ""
        },
        showContentLoader() {
            return this.isLoader 
        },
        isLoader() {
            return false
        },
        readOnly() {

        },
        _isCompactMode() {
            return this.isFullCompactMode || this.isCompactMode
        },        
        ...mapGetters(USERDATA, [GET_UID]),
        // ...mapGetters(CHAT, [GET_CHAT_MEDIA_SEARCH_STRING]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    watch: {
        // focusedInput(val) {
        //     console.log("🚀 ~ file: Threads.vue:154 ~ focusedInput ~ val:", val)
        // }
    },
    methods: {
        setInputFocus(val) {
            this.focusedInput = val
            console.log("🚀 ~ file: Threads.vue:160 ~ setInputFocus ~ this.focusedInput:", this.focusedInput)
        },
        formComments() {
            let res = {}, sorted = {}
            this.commentsMockData.forEach(msg => {
                console.log("🚀 ~ file: Threads.vue:156 ~ formComments ~ msg:", msg)
                let cid = msg.cid
                let fio = this.getFio(cid)
                console.log("🚀 ~ file: Threads.vue:154 ~ formComments ~ fio:", fio)
                let photo = this.getPhoto(cid)
                let m_time = moment(msg.time)
                let formatedDate = this.formatDate(msg.time)
                console.log("🚀 ~ file: Threads.vue:153 ~ formComments ~ formatedDate:", formatedDate)
                let time = m_time.format("HH:mm")
                if (!res[formatedDate]) res[formatedDate] = []
                res[formatedDate].push({cid, fio, photo, comment: msg.comment, time})
            })
            let reversedKeys = Object.keys(res).sort()
            const compare = (a, b) => {
                if ( a.time < b.time ) return -1
                if ( a.time > b.time ) return 1
                return 0
            }
            reversedKeys.forEach((k, i) => {
                sorted[k] = res[k].sort(compare)
            })
            this.comments = res //sorted
            console.log("🚀 ~ file: Threads.vue:166 ~ formComments ~ this.comments:", this.comments)
        },
        formatDate(time) {
            let result
            let u_time = moment(time)
            let today = moment().startOf('day')
            let yesterday = moment().subtract(1, 'days').startOf('day')
            if(u_time.isAfter(today)) {
                result = this.$t('settings.today')
            } else if(u_time.isBefore(today) && u_time.isAfter(yesterday)) {
                result = this.$t('channel-comp.yesterday')
            } else {
                result = u_time.format('DD MMM YYYY')
            }
            return result
        },        
        getPhoto(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).photo || ""
        },
        getFio(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || ""
        },
        getMessageItem(message) {
            const cid = message.cid
            const isGroupChat = message.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
            const contact = app.store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
            let remoteServerId = contact && contact.objectId
            if (isGroupChat) {
                const externalContact = app.store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.senderId)
                remoteServerId = externalContact && externalContact.objectId
            }
            let out = message.type === 'out' || app.getUid() === message.senderId
            let time = date_helper.secondsLeftToTimeFormat(message.time)
            const isPollId = message.data.pollId || false
            if (isPollId) message.data.type = 'poll'
            return {
                id: message.id,
                chId: '',
                cid: message.cid,
                cidType: message.cidType,
                senderId: message.senderId,
                author_tmp: message.author,
                type: message.dataType,
                sub_type: message.data && message.data.type,
                fileGroupId: message.data && message.data.fileGroupId,
                date: this.formatTime(message.time),
                month: this.formatTimeAsMonth(message.time),
                time,
                msg: this.getItemText(message),
                linkItemHeader: this.mediaType === "link" ? message.data.text.substr(0, 40) : null,
                text: message.data.text,
                ownMsg: out,
                remoteServerHost: remoteServerId && message.type !== 'out' ? app.store.getters[`${USERDATA}/${GET_SERVER_REMOTE_HOST}`](remoteServerId) : false,
            }
        },
        getChannelMessageItem(message) {
            if (!Object.keys(message).length) return {}
            let out = message.type === "out" || app.getUid() === message.senderId
            if (message.data.pollId) message.data.type = "poll"
            return {
                chId: message.chId,
                pubId: message.pubId,
                cidType: message.cidType,
                date: this.formatTime(message.time),
                month: this.formatTimeAsMonth(message.time),
                //time: date_helper.secondsLeftToTimeFormat(message.time),
                time: message.time,
                msg: message.data,
                title: message.hasOwnProperty("title") ? message.title : '', 
                info: message.hasOwnProperty("info") ? message.info : '',
                ownMsg: out
            }
        },
        getItemText(message) {
            let text = ""
            switch (message.dataType) {
                case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                    if (message.data.pollId) message.data.type = "poll"
                    if (this.mediaType === "link") {
                        text = getDataText(message.data, true)
                    } else text = getDataText(message.data)
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                    text = ""
                    break
            }
            return text
        },
        formatTime(timeCall) {
            let result
            let timeCallFormat = moment().subtract(timeCall, "seconds")
            let today = moment().startOf("day")
            let yesterday = moment()
                .subtract(1, "days")
                .startOf("day")
            let twoDays = moment()
                .subtract(2, "days")
                .startOf("day")
            if (timeCallFormat.isAfter(today)) {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("HH:mm")
            } else if (
                timeCallFormat.isBefore(today) &&
                timeCallFormat.isAfter(yesterday)
            ) {
                result = this.$t("channel-comp.yesterday")
            } else if (
                timeCallFormat.isBefore(yesterday) &&
                timeCallFormat.isAfter(twoDays)
            ) {
                result = this.$t("channel-comp.before-yest")
            } else {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("DD MMM YYYY")
            }
            return result
        },
        formatTimeAsMonth(timeCall) {
            const timeWithoutSec = moment().subtract(timeCall, "seconds")
            const nowTimeYear = moment().format("YYYY")
            const isCurrentYear = timeWithoutSec.format("YYYY") === nowTimeYear
            return isCurrentYear
                ? timeWithoutSec.format("MMMM")
                : timeWithoutSec.format("MMMM YYYY")
        },
        onContextMenu({ e, message }) {
            let handlers = [], id = message.id, fileGroupId = message.fileGroupId, chId = message.chId, pubId = message.pubId
            if (fileGroupId) {
                const tmp_msg = this.foundChats.filter(msg => msg.id === id)[0]
                const groupMsgsIds = this.foundChats.filter(msg => msg.fileGroupId === tmp_msg.fileGroupId).map(msg => msg.id)
                id = Math.min(...groupMsgsIds)
            }
            
            if (id) {
                handlers.push({
                    item_name: this.$t('media-search.goto-chat'),
                    handler: this.scrollToMessageContext,
                    data: id
                })
            } else if (message.chId && message.pubId) {
                handlers.push({                                                                         
                    item_name: this.$t('media-search.goto-publication'),
                    handler: this.showPublication,
                    data: message
                })
            }
            if (id) {
                if (!(message.msg && message.msg.readOnly)) {
                    handlers.push({
                        item_name: this.$t('media-search.forward'),
                        handler: this.forwardMessage,
                        data: message
                    })
                }
                handlers.push({
                    item_name: this.$t('media-search.delete'),
                    handler: this.removeMsg,
                    data: message
                })
            }
            this.cmOpen(e, handlers, "right-bottom")
        },
        showPublication(msg) {
            const isCompactMode = this._isCompactMode
            if (isCompactMode) {
                this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
                setTimeout(() => this.checkPublicationExist(msg.chId, msg.pubId), 300) 
            } else this.checkPublicationExist(msg.chId, msg.pubId)
        },
        scrollToMessageContext(id) {
            const isCompactMode = this._isCompactMode
            if (isCompactMode) {
                this.$nextTick(() => { this[ACT_INFO_CLEAR]() })
                if (id) setTimeout(() => this.scrollingToElement(id), 300) 
            }
            else if (id) this.scrollingToElement(id)
        },
        forwardMessage(message) {
            message = JSON.parse(JSON.stringify(message))
            let data = message.msg
            let entities = message.entities
            let dataType, author = message.author_tmp ? message.author : message.senderId
            if (message.type === 'text') {
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_TEXT
            } else {
                if (message.sub_type === 'text') {
                    data = {
                        type: message.sub_type,
                        text: data,
                    }
                    if (!entities) entities = this.extractInputTextFormat(data.text).entities || []
                    data.entities = entities
                } else {
                    data.type = message.sub_type
                }
                if (data.file) {
                    data.file = data.file.split('/').pop();
                }
                if (message.sub_type === 'location') {
                    let { type, latitude, longitude } = data
                    data = { type, latitude, longitude }
                }
                if (message.sub_type === 'contact') {
                    data.fields = message.fields
                }

                data = JSON.stringify(data)
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
            }
            this.modalOpen({
                component: SelectChatToForward,
                props: {
                    msg: { dataType, data, author },
                }
            })
        },
        removeMsg(message) {
            this.modalOpen({
                component: DelChatMessage,
                props: {
                    msg: message,
                    btnOk: {
                        cb: () => { 
                            this.isTabSwitched = true
                            this.searchMessages()
                        }
                    }
                }
            })
        },
        sendComment() {

        },
        showBotCommand() {

        },
        ...mapActions(INFO, [ACT_INFO_CLEAR]),
    },
    watch: {

    }
}

function getDataText(data, withLinks = false) {
    let text = ""
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            const fields = Array.isArray(data.fields) && data.fields
            const phones =
                fields &&
                fields.filter(f => f.type === CONTACT_FIELD_TYPES.PHONE || f.type === CONTACT_FIELD_TYPES.WORKPHONE)
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
            const mail = mails && mails.length && mails[0].value
            const fio = (fields && fields.fio) || phone || mail
            text = fio
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = data.longitude + " " + data.latitude
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.name + '.' + data.extension,
                readOnly: data.readOnly,
                key: data.key,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = data.topic
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            if (withLinks) {
                let links = []
                const linksEntities = data.entities.filter(
                    ent => ent.type === "url" || ent.type === "mail" || ent.type === "textUrl"
                )
                linksEntities.forEach(entity => {
                    if (entity.type !== "textUrl") {
                        let link = data.text.substr(entity.offset, entity.length)
                        if (entity.length) links.push(`${link}`)
                    } else {
                        const textPart = data.text.substr(entity.offset, entity.length)
                        const urlPart = entity.url
                        let textUrlLink = `@[${textPart}][url:${urlPart}]`
                        if (entity.length) links.push(textUrlLink)
                    }
                })
                text = links
            } else text = data.text
            break
        default:
            text = ""
    }
    return text
}
