//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { CONTENT_MANAGER, CONTACTS, CLIENTDATA, CHAT } from "../../store/modulesNames"
import { ACT_PUSH_MAIN_TYPE, ACT_REPLACE_MAIN_TYPE, ACT_SET_CURRENT_COMMENTS } from "../../store/actionsTypes"
import { GET_LOCALE, GET_MERGED_CONTACT_BY_ID, GET_OPENED_MAIN_TYPE } from "../../store/gettersTypes"
import { MAIN_TYPES } from "../../store/modules/content-manager"
import ChatMessageMixin from "./chat-message-mixin"

export default {
    name: "chat-threads",
    props: {
        message: {
            type: Object,
        },
        isFirstComment: {
            type: Boolean,
            default: false,
        },
        isLeftSide: {
            type: Boolean,
            default: false
        },
    },
    mixins: [ChatMessageMixin],
    components: { },
    data() {
        return {
            isMouseOver: false,
            isUnread: false,
        }
    },
    mounted() {
        if (this.isFirstComment) this.isUnread = true
    },
    watch: {
        count(newVal, oldVal) {
            const isNotThreadsOpened = this[GET_OPENED_MAIN_TYPE].type !== MAIN_TYPES.THREADS
            if (isNotThreadsOpened && ((oldVal && newVal > oldVal) || (!oldVal && newVal === 1))) {
                    this.isUnread = true
            }
        },
    },
    computed: {
        commentsText() {
            const count = this.count
            return utils.declOfNum(count, [ this.$t('chat.one-comment'), this.$t('chat.few-comments'), this.$t('chat.many-comments')])
        },
        commentId() {
            return this.message.id
        },
        cid() {
            return this.message.cid
        },
        count() {
            return this.message.comments
        },
        isCommentsSubscribed() {
            return this.message.commentsSubscribed
        },
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CONTENT_MANAGER, [GET_OPENED_MAIN_TYPE]),
    },
    methods: {
        async openThreads() {
            await this[ACT_SET_CURRENT_COMMENTS]({ cid: this.cid, commentId: this.commentId})
            let msg = this.getMsgNameByType(this.message)
            let auxParams = { commentId: this.commentId, isCommentsSubscribed: this.isCommentsSubscribed, message: msg }
            this[ACT_PUSH_MAIN_TYPE]({type: MAIN_TYPES.THREADS, auxParams})
        },
        mouseOver(e) {
            const msgListElem = e.target.offsetParent && e.target.offsetParent.offsetParent
            msgListElem.classList.add('threads-mouse-over')
        },
        mouseLeave(e) {
            const msgListElem = e.target.offsetParent && e.target.offsetParent.offsetParent
            msgListElem.classList.remove('threads-mouse-over')
        },
        ...mapActions(CONTENT_MANAGER, [ACT_PUSH_MAIN_TYPE, ACT_REPLACE_MAIN_TYPE]),
        ...mapActions(CHAT, [ACT_SET_CURRENT_COMMENTS]),        
    },

}
